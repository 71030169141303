<template>
    <div class="navTop" id="indexH5">
        <!-- 顶部 -->
        <div class="nav">
            <div class="navCon">
                <h2 @click="indexUrl()">中国外文局亚太传播中心</h2>
                <!-- 切换多语言 -->
                <!-- <div class="language">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            {{$t('language')}}：
                            <img v-if="tablang=='cn'" src="../assets/images/cn.png" alt="">
                            <img v-if="tablang=='uk'" src="../assets/images/uk.png" alt="">
                            <img v-if="tablang=='jp'" src="../assets/images/jp.png" alt="">
                            <img v-if="tablang=='kr'" src="../assets/images/kr.png" alt="">
                             {{tablang}}
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="cn"><img src="../assets/images/cn.png" alt=""> 简体中文(CN)</el-dropdown-item>
                            <el-dropdown-item command="uk"><img src="../assets/images/uk.png" alt=""> ENGLISH（UK）</el-dropdown-item>
                            <el-dropdown-item command="jp"><img src="../assets/images/jp.png" alt=""> にほんご（JP）</el-dropdown-item>
                            <el-dropdown-item command="kr"><img src="../assets/images/kr.png" alt=""> 한국어（KR）</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div> -->
               <!-- 更多按钮 -->
                <!-- <div class="more">
                        <el-dropdown>
                        <span class="el-dropdown-link">
                            <img src="../assets/images/more.png" alt="">
                            {{$t('More')}}
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>{{$t('More')}}</el-dropdown-item>
                            <el-dropdown-item>{{$t('More')}}</el-dropdown-item>
                            <el-dropdown-item>{{$t('More')}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div> -->
            </div>
        </div>

        <!-- 内容 -->
        <div class="content">
            <!-- <div class="map"> -->
                <img class="blockBgc mapBgc" src="../assets/images/twoBgc.png" alt="">
                <!-- <img class="redBgc mapBgc" src="../assets/images/redBgc.png" alt=""> -->
                <!-- <video-player class="video-player vjs-custom-skin"
                      ref="videoPlayer"
                      :playsinline="true"
                      :options="playerOptions">
                </video-player> -->
            <!-- </div> -->
            <div class="mapCard">
                <div class="mapText">
                    <div class="block">
                        <div class="logo"><img src="../assets/images/APC@3x@2x.png" alt=""></div>
                        <div class="title">{{$t('title')}}</div>
                        <div class="Annotation">CICG Asia-Pacific</div>
                        <!-- <img class="logoText" src="../assets/images/LOGO.png" alt=""> -->
                            <div class="line">
                                <span></span>
                                <em></em>
                                <span></span>
                            </div>
                        <p class="p">{{$t('titleP')}}</p>
                    </div>
                    <div class="card">
                        <ul>
                            <li>
                                <img class="img" src="../assets/images/li1.png" alt="">
                                <h5>{{$t('li1_tit')}}</h5>
                                <h6>Chinareports</h6>
                                <div class="details"><img src="../assets/images/right.png" alt=""> {{$t('Mores')}}</div>
                                <div class="active">
                                    <h5>{{$t('li1_tit')}}</h5>
                                    <h6>Chinareports</h6>
                                    <div class="horizontal"></div>
                                    <p>{{$t('li1P')}}</p>
                                    <a href="http://www.chinareports.org.cn/">{{$t('herf')}}></a>
                                </div>
                            </li>
                            <li>
                                <img class="img" src="../assets/images/li2.png" alt="">
                                <h5>{{$t('li2_tit')}}</h5>
                                <h6>PEOPLE'S CHINA</h6>
                                <div class="details"><img src="../assets/images/right.png" alt=""> {{$t('Mores')}}</div>
                                <div class="active">
                                    <h5>{{$t('li2_tit')}}</h5>
                                    <h6>PEOPLE'S CHINA</h6>
                                    <div class="horizontal"></div>
                                    <p>{{$t('li2P')}}</p>
                                    <a href="http://www.peoplechina.com.cn/">{{$t('herf')}}></a>
                                </div>
                            </li>
                            <li>
                                <img class="img" src="../assets/images/li3.png" alt="">
                                <h5>{{$t('li3_tit')}}</h5>
                                <h6>Beijing Review</h6>
                                <div class="details"><img src="../assets/images/right.png" alt=""> {{$t('Mores')}}</div>
                                <div class="active">
                                    <h5>{{$t('li3_tit')}}</h5>
                                    <h6>Beijing Review</h6>
                                    <div class="horizontal"></div>
                                    <p>{{$t('li3P')}}</p>
                                    <a href="http://japanese.beijingreview.com.cn/">{{$t('herf')}}></a>
                                </div>
                            </li>
                            <li>
                                <img class="img" src="../assets/images/li4.png" alt="">
                                <h5>{{$t('li4_tit')}}</h5>
                                <h6>Chinakorea</h6>
                                <div class="details"><img src="../assets/images/right.png" alt=""> {{$t('Mores')}}</div>
                                <div class="active">
                                    <h5>{{$t('li4_tit')}}</h5>
                                    <h6>Chinakorea</h6>
                                    <div class="horizontal"></div>
                                    <p>{{$t('li4P')}}</p>
                                    <a href="http://www.chinacorea.com/">{{$t('herf')}}></a>
                                </div>
                            </li>
                            <li>
                                <img class="img" src="../assets/images/li5.jpg" alt="">
                                <h5>{{$t('li5_tit')}}</h5>
                                <h6>ChinaReport</h6>
                                <div class="details"><img src="../assets/images/right.png" alt=""> {{$t('Mores')}}</div>
                                <div class="active">
                                    <h5>{{$t('li5_tit')}}</h5>
                                    <h6>ChinaReport</h6>
                                    <div class="horizontal"></div>
                                    <p>{{$t('li5P')}}</p>
                                    <a href="https://chinareportasean.com/">{{$t('herf')}}></a>
                                </div>
                            </li>
                            <li>
                                <img class="img" src="../assets/images/li6.png" alt="">
                                <h5>{{$t('li6_tit')}}</h5>
                                <h6>ELPOPLA CINIO</h6>
                                <div class="details"><img src="../assets/images/right.png" alt=""> {{$t('Mores')}}</div>
                                <div class="active">
                                    <h5>{{$t('li6_tit')}}</h5>
                                    <h6>ELPOPLA CINIO</h6>
                                    <div class="horizontal"></div>
                                    <p>{{$t('li6P')}}</p>
                                    <a href="http://www.espero.com.cn/">{{$t('herf')}}></a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- H5 -->
        <div class="mobileH5">
            <div class="navH5 ">
               <!-- 更多按钮 -->
                <!-- <div class="more">
                        <el-dropdown>
                        <span class="el-dropdown-link">
                            <img src="../assets/images/more.png" alt="">
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>{{$t('More')}}</el-dropdown-item>
                            <el-dropdown-item>{{$t('More')}}</el-dropdown-item>
                            <el-dropdown-item>{{$t('More')}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div> -->
                
                 <!-- 切换多语言 -->
                <!-- <div class="language">
                    <el-dropdown  @command="handleCommand">
                        <span class="el-dropdown-link">
                            {{tablangH5}}
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="cn"><img src="../assets/images/cn.png" alt=""> 简体中文(CN)</el-dropdown-item>
                            <el-dropdown-item command="uk"><img src="../assets/images/uk.png" alt=""> ENGLISH（UK）</el-dropdown-item>
                            <el-dropdown-item command="jp"><img src="../assets/images/jp.png" alt=""> にほんご（JP）</el-dropdown-item>
                            <el-dropdown-item command="kr"><img src="../assets/images/kr.png" alt=""> 한국어（KR）</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div> -->
                <!-- <span class="span">{{$t('titleH5')}}</span> -->
                <div class="log"><img src="../assets/images/APC@3x@2x.png" alt=""></div>
                <div class="title">{{$t('title')}}</div>
                <div class="Annotation">CICG Asia-Pacific</div>
                <!-- <p class="p">{{$t('titleP')}}</p> -->
                <!-- <p class="p">{{$t('titlePHide')}}</p> -->
                <div class="cardTop">
                    <p class="p">{{titleText}}</p>
                    <div v-if="titleType" class="titleNumber" @click="titleClick(1)">查看更多</div>
                    <div v-if="!titleType" class="titleNumberUp" @click="titleClick(2)">收起</div>
                </div>
            </div>
            <ul>
                <li>
                     <a href="http://www.chinareports.org.cn/">
                        <img class="img" src="../assets/images/li1.png" alt="">
                        <h5>{{$t('li1_tit')}}</h5>
                        <h6>Chinareports</h6>
                        <p>{{$t('li1P')}}</p>
                    </a>
                </li>
                <li>
                    <a href="http://www.peoplechina.com.cn/">
                        <img class="img" src="../assets/images/li2.png" alt="">
                        <h5>{{$t('li2_tit')}}</h5>
                        <h6>PEOPLE'S CHINA</h6>
                        <p>{{$t('li2P')}}</p>
                            
                    </a>
                </li>
                <li>
                     <a href="http://japanese.beijingreview.com.cn/">
                        <img class="img" src="../assets/images/li3.png" alt="">
                        <h5>{{$t('li3_tit')}}</h5>
                        <h6>Beijing Review</h6>
                        <p>{{$t('li3P')}}</p>
                     </a>
                </li>
                <li>
                    <a href="http://www.chinacorea.com/">
                        <img class="img" src="../assets/images/li4.png" alt="">
                        <h5>{{$t('li4_tit')}}</h5>
                        <h6>Chinakorea</h6>
                        <p>{{$t('li4P')}}</p>
                            
                    </a>
                </li>
                <li>
                    <a href="https://chinareportasean.com/">
                        <img class="img" src="../assets/images/li5.jpg" alt="">
                        <h5>{{$t('li5_tit')}}</h5>
                        <h6>ChinaReport</h6>
                        <p>{{$t('li5P')}}</p>
                            
                    </a>
                </li>
                <li>
                    <a href="http://www.espero.com.cn/">
                        <img class="img" src="../assets/images/li6.png" alt="">
                        <h5>{{$t('li6_tit')}}</h5>
                        <h6>ELPOPLA CINIO</h6>
                        <p>{{$t('li6P')}}</p>
                    </a>
                </li>
                    
            </ul>
        </div>
        <!-- 底部 -->
        
        <div class="footer">
            <div class="footCon">
                <div>
                    <ul>
                        <li><a @click="details(1)">{{$t('foot1')}}</a></li>
                        <li><a @click="details(2)">{{$t('foot2')}}</a></li>
                        <li><a @click="details(3)">{{$t('foot3')}}</a></li>
                        <li><a @click="details(4)">{{$t('foot4')}}</a></li>
                        <li><a @click="details(5)">{{$t('foot5')}}</a></li>
                    </ul>
                </div>
                <p>
                    {{$t('footP1')}}
                </p>
                <p>
                    {{$t('footP2')}}
                </p>
                <div class="p">{{$t('Fp1')}}</div>
                <div class="p">{{$t('Fp2')}}</div>
                <!-- <div class="p">{{$t('Fp3')}}</div>
                <div class="p">{{$t('Fp4')}}</div> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            tablang:'cn',
            tablangH5:'Chinese',
            titleType:true, //查看更多按钮
            titleText:this.$t('titlePHide'),//H5显示文案
            playerOptions: {
                    //播放速度
                    playbackRates: [0.5, 1.0, 1.5, 2.0], 
                    //如果true,浏览器准备好时开始回放。
                    autoplay: true, 
                    // 默认情况下将会消除任何音频。
                    muted: true, 
                    // 导致视频一结束就重新开始。
                    loop: false, 
                    // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    preload: 'auto', 
                    language: 'zh-CN',
                     // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    aspectRatio: '16:9',
                     // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    fluid: true,
                    sources: [{
                        //类型
                        type: "video/mp4",
                        //url地址
                        src: require('../assets/video.mp4')
                    }],
                    //你的封面地址
                    poster: '', 
                     //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    notSupportedMessage: '此视频暂无法播放，请稍后再试',
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: false,
                        //全屏按钮
                        fullscreenToggle: true  
                    }
                }
        }
    },
    mounted () {
        
    },
    methods: {
        handleCommand(command){
            console.log(command);
            this.$i18n.locale=command
            this.tablang=command
            this.tablangH5=command
            localStorage.setItem('lang',command)
        },
        // 查看更多按钮
        titleClick(type){
            if(type==1){
                this.titleType=false;
                this.titleText=this.$t('titleP');    
            }else{
                this.titleType=true;
                this.titleText=this.$t('titlePHide');
            }
        },
        indexUrl(){
            this.$router.push({
                path: '/'
            })
        },
        details(id){
            this.$router.push({
                path: '/Details',
                query:{
                    footType:id
                }
            })
        },
    }
}
</script>
<style lang="less">
    // 下拉框样式
    .el-dropdown-menu{
        img{
            width: 23px;
            margin-right: 16px;
        }
        .el-dropdown-menu__item{
            font-size: 14px;
            height: 46px;
            line-height: 46px;
            border-bottom: 1px solid #FAFAFA;
            box-sizing: border-box;
            border-left: 2px solid #fff;   
        }
        .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover{
            color: #333;
            background-color: #fff;
            border-left: 2px solid @myTheme;   
        }
    }
    .navTop{
        height: 100%;
        overflow: hidden;
        .nav{
            position: relative;
            z-index: 1;
            background-color: @myTheme;
            height: 36px;
            line-height: 36px;
            width: 100%;
            font-size: 14px;
            color: #fff;
            // 顶部样式
            .navCon{
                letter-spacing: 1px;
                color: #fff;
                h2{
                    cursor: pointer;
                    font-size: 14px;
                    color: #fff;
                    font-weight: 400;
                    float: left;
                    margin-left: 16%;
                }
                .el-dropdown{
                    color: #fff;
                    font-size: 14px;
                }
                .more{
                    float: right;
                    margin-right: 35px;
                    img{
                        width: 14px;
                        margin-right: 2px;
                        vertical-align: text-top;
                    }
                }
                .language{
                    float: right;
                    margin-right: 38px;
                    img{
                        width: 27px;
                    }
                    .el-dropdown{
                        padding-right: 30px;
                    }
                    .el-dropdown::after{
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 12px;
                        transform: translateY(-50%);
                        width: 0;
                        height: 0;
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                        border-top: 4px solid #fff;
                    }
                }
            }
        }
        // 内容
        .content{
            position: relative;
            // padding-bottom:100px;
            // height: calc( ~ "100%" - 135px);
            height: 100%;
            overflow: hidden;
            // 背景地图
                .video-js{
                    background-color: #fff;
                    height: 100%;
                }
                .vjs-control-bar{
                    display: none;
                }
                .video-js .vjs-big-play-button{
                    display: none!important;
                }
                .video-js .vjs-tech{
                    // height: 1040px;
                }
                .video-player{
                    position: absolute;
                    left: 0px;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
                .video-js .vjs-tech {
                    object-fit: fill;
                }
                .vjs-poster {
                    background-size: cover;
                }
                .mapBgc{
                    width: 100%;
                    height: calc( ~ "100%" - 135px);
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 0px;
                    animation: blockHide 2s  linear;
                    // width: 1800px;
                    // animation: map_left 2s  linear 1s;
                }
                .redBgc{
                    opacity: 1;
                    animation: map_left_red 2s  linear 1s;
                }
            
            // 主要内容
            .mapCard{
                position: relative;
                z-index: 1;
                width: 100%;
                height: calc( ~ "100%" - 135px);
                background-color: rgba(80, 80, 80, 0.5);
                animation: HideBgc 2s  linear;
                .mapText{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    // overflow: hidden;
                    width: @content;
                    margin: 0 auto;
                    text-align: center;
                    color: #fff;
                    // padding-bottom: 100px;
                    .block{
                        opacity: 1;
                        animation: blockHide 2s  linear;
                        .logoText{
                            width: 355px;
                        }
                    }
                    .logo{
                        margin-bottom: 15px;
                        img{
                            width: 272px;
                        }
                    }
                    .title{
                        font-size: 30px;
                        line-height: 30px;
                        // margin-top: 200px;
                        margin-bottom: 7px;
                    }
                    .Annotation{
                        font-size: 17px;
                        line-height: 17px;
                    }
                    .line{
                        width: 400px;
                        height: 5px;
                        margin: 20px auto;
                        span{
                            width: 190px;
                            height: 1px;
                            background: linear-gradient(90deg, #FFFFFF 0%, rgba(128, 128, 128, 0) 100%);
                            opacity: 1;
                            float: left;
                            margin-top: 2px;
                        }
                        em{
                            float: left;
                            width: 4px;
                            height: 4px;
                            background: #FFFFFF;
                            border-radius: 50%;
                            opacity: 1;
                            margin: 0 4px;
                        }
                        span:nth-child(1){
                            width: 190px;
                            height: 1px;
                            background: linear-gradient(-90deg, #FFFFFF 0%, rgba(128, 128, 128, 0) 100%);
                            opacity: 1;
                        }
                        
                    }
                    .p{
                        text-indent: 2em;
                        text-align: left;
                        width: 1073px;
                        margin: 0 auto;
                        font-size: 17px;
                        color: #fff;
                        line-height: 1.8;
                    }
                }
                .card{
                    margin-top: 42px;
                    text-align: left;
                    ul{
                        position: relative;
                        top: 0px;
                        width: 100%;
                        display: flex; 
                        justify-content: space-between;
                        flex-wrap: wrap;
                        animation: cardHide 2s  linear;
                        li{
                            background-color: #fff;
                            padding: 26px 0;
                            position: relative;
                            width: 194px;
                            height: 220px;
                            box-sizing: border-box;
                            overflow: hidden;
                            border-bottom: 4px solid @myTheme;
                            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
                            .img{
                                height: 40px;
                                padding-left: 10px;
                            }
                            h5{
                                padding: 0 10px;
                                font-size: 14px;
                                margin-top: 34px;
                                color: @title;
                            }
                            h6{
                                padding: 0 10px;
                                font-size: 13px;
                                margin-top: 6px;
                                margin-bottom: 15px;
                                color: @text;
                                font-weight: 400;
                            }
                            .details{
                                border-top: 1px solid rgba(153, 153, 153, 0.2);
                                padding-top: 12px;
                                padding-left: 10px;
                                font-size: 14px;
                                color: #030303;
                                img{
                                    width: 12px;
                                }
                            }

                            .active{
                                opacity: 0;
                                width: 100%;
                                height: 100%;
                                background-color: rgba(0, 0, 0, 0.7);
                                position: absolute;
                                top: 0;
                                left: 0;
                                padding: 22px 10px;
                                h5{
                                    color: #fff;
                                    margin-top: 0;
                                    padding: 0;
                                }
                                h6{
                                    margin-top: 0;
                                    color: #fff;
                                    padding: 0;
                                    margin-bottom: 0;
                                }
                                .horizontal{
                                    margin-top: 2px;
                                    margin-bottom: 6px;
                                    width: 40px;
                                    height: 3px;
                                    background-color: #fff;
                                }
                                p{
                                    font-size: 12px;
                                    width: 160px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-box-orient:vertical;
                                    -webkit-line-clamp:5;
                                }
                                a{
                                    color: #fff;
                                    font-size: 12px;
                                    margin-top: 10px;
                                    display: inline-block;
                                }
                            }
                            .active:hover{
                                opacity: 1;
                                transition: all 0.5s linear;
                            }
                        }
                    }
                }
            }
        }
        // 底部样式
        .footer{
            background-color: @myTheme;
            overflow: hidden;
            position: fixed;
            bottom: 0;
            width: 100%;
            z-index: 1;
            .footCon{
                width: @content;
                margin: 0 auto;
                color: #fff;
                font-size: 12px;
                text-align: center;
                ul{
                        display: inline-block;
                        margin: 15px auto 5px;
                    li{
                        float: left;
                        a{
                            cursor: pointer;
                            padding: 0px 15px;
                            color: #fff;
                            font-size: 12px;
                            border-right: 1px solid #fff;
                        }
                    }
                    li:last-child a{
                        border-right:0;
                    }
                }
                p{
                    margin-bottom: 10px;
                }
                .p{
                    display: none;
                }
            }
        }
    }
    .mobileH5{
        display: none;
    }
    // 低于750px
    @media (max-width: 750px) {
        .navTop{
            overflow: auto;
        }
        .app{
            // .px2rem(width,750);
            min-width: auto;
        }
        .content{
            display: none;
        }
        #indexH5{
            .nav{
                display: none;
            }
            .mobileH5{
                display: block;
                padding-bottom: 2rem;
                .navH5{
                    position: relative;
                    // .px2rem(height,320);
                    display: block;
                    width: 100%;
                    background-color: @myTheme;
                    font-size: 0.3rem;
                    text-align: center;
                    box-sizing: border-box;
                    color: #fff;
                    padding-top: 0.5rem;
                    text-align: left;
                    padding: 0 0.41rem;
                    border-top: 0.01rem solid @myTheme;
                    padding-bottom: 1.8rem;
                    .log{
                        margin-top: 0.4rem;
                        img{
                            width: 2.6rem;
                        }
                    }
                    .title{
                        font-size: 0.26rem;
                        font-weight: 700;
                        margin-top: 0.1rem;
                    }
                    .Annotation{
                        font-size: 0.2rem;
                        margin-top: 0.08rem;
                        margin-bottom: 0.2rem;
                        
                        color: rgba(255, 255, 255, 0.9);
                    }
                    .p{
                        font-size: 0.22rem;
                        color: rgba(255, 255, 255, 0.9);
                    }
                    .cardTop{
                        position: relative;
                        font-size: 0.22rem;
                        color: rgba(255, 255, 255, 0.9);
                        .titleNumber{
                            position: absolute;
                            right: 0;
                            top: 0.95rem;
                            font-weight: 700;
                        }
                        .titleNumberUp{
                            font-weight: 700;
                            text-align: right;
                        }
                    }
                    .more{
                        float: right;
                        margin-right: 0.3rem;
                        img{
                            width: 0.4rem;
                        }
                    }
                    .span{
                        position: absolute;
                        left: 50%;
                        top: 0.5rem;
                        transform: translateX(-50%);
                    }
                    .language{
                        float: left;
                        margin-left:0.44rem ;
                        .el-dropdown{
                            position: relative;
                            font-size: 0.3rem;
                            color: #fff;
                            padding-right: 0.4rem;
                        }
                        .el-dropdown::after{
                            content: '';
                            position: absolute;
                            top: 50%;
                            right: 0.1rem;
                            transform: translateY(-50%);
                            width: 0;
                            height: 0;
                            border-left: 0.1rem solid transparent;
                            border-right: 0.1rem solid transparent;
                            border-top: 0.1rem solid #fff;
                        }
                    }
                }
                ul{
                    position: relative;
                    top: -1.4rem;
                    width: 7rem;
                    margin: 0 auto;
                    li{
                        // height: 2.58rem;
                        background: #FFFFFF;
                        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                        opacity: 1;
                        border-radius: 12px;
                        margin-bottom: 0.1rem;
                        padding: 0.36rem;
                        .img{
                            float: right;
                            height: 0.4rem;
                        }
                        h5{
                            font-size: 0.28rem;
                        }
                        h6{
                            font-size: 0.22rem;
                            // margin-top: 0.1rem;
                            font-weight: 400;
                            margin-bottom: 0.2rem;
                        }
                        p{
                            font-size: 0.22rem;
                        }
                    }
                }
            }
            .footCon{
                width: 7.5rem;
                font-size: 0.2rem;
                padding-bottom: 0.2rem;
                li{
                    a{
                        font-size: 0.2rem;
                        
                        padding: 0 0.2rem;
                    }
                }
                p{
                    display: none;
                }
                .p{
                    display: block;
                    text-align: center;
                    font-size: 0.14rem;
                    // margin-bottom: 0.2rem;
                }
                
            }
        }
	}
     /*滑入——从顶部*/
    @keyframes map_left {
        0% {
            left: 400px;
        }
        50%{
            left: -300px;
        }
        100% {
            left: -300px;
        }
    }
    @keyframes map_left_red {
        0% {
            left: 400px;
            opacity: 0;
        }
        50%{
            left: -300px;
            opacity: 0;
        }
        100% {
            left: -300px;
            opacity: 1;
        }
    }
    @keyframes cardHide {
        0% {
            top: 320px;
        }
        33%{
            top: 320px;
        }
        100% {
            top: 0px;
        }
    }

    @keyframes blockHide {
        0% {
            opacity: 0;
        }
        25%{
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes HideBgc {
        0% {
            background-color: rgba(80, 80, 80, 0);
        }
        25%{
            background-color: rgba(80, 80, 80, 0)
        }
        100% {
            background-color: rgba(80, 80, 80, 0.5);
        }
    }
</style>